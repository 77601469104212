import { Elm } from "./Main.elm";

const state = String(localStorage.getItem("omg"));

const app = Elm.Main.init({
  flags: state,
  node: document.getElementById("root")
});

app.ports.storeState.subscribe(message => {
  console.log(message)
  localStorage.setItem("omg", message);
});
