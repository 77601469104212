module Main exposing (main)

import AddPlayersPage
import Array
import Browser
import Browser.Navigation as Nav
import Course exposing (findCourse, findCourseBySlug, findHole)
import Dict exposing (Dict)
import HolePage
import HomePage
import Html exposing (Html, a, div, text)
import Html.Attributes exposing (class, href)
import Json.Decode as Decode
import Json.Encode as Encode
import Ports
import ResultsPage
import Route exposing (Route)
import Types exposing (..)
import Url exposing (Url)


type UrlRequest
    = Internal Url
    | External String


playerDecoder : Decode.Decoder Player
playerDecoder =
    Decode.map4 Player
        (Decode.field "id" Decode.string)
        (Decode.field "name" Decode.string)
        (Decode.field "active" Decode.bool)
        (Decode.field "index" Decode.int)


playersDecoder : Decode.Decoder (List Player)
playersDecoder =
    Decode.field "players" (Decode.list playerDecoder)


encodePlayer : Player -> Encode.Value
encodePlayer player =
    Encode.object
        [ ( "id", Encode.string player.id )
        , ( "name", Encode.string player.name )
        , ( "active", Encode.bool player.active )
        , ( "index", Encode.int player.index )
        ]


encodeScoreKey : ( String, String ) -> String
encodeScoreKey key =
    String.join "/" [ Tuple.first key, Tuple.second key ]


intoTuple : ( String, Int ) -> ( ( String, String ), Int )
intoTuple tuple =
    let
        ( kkey, score ) =
            tuple
    in
    case String.split "/" kkey of
        [ holeId, playerId ] ->
            ( ( holeId, playerId ), score )

        _ ->
            ( ( "a", "b" ), score )


decodeScore : List ( String, Int ) -> Scores
decodeScore list =
    list
        |> List.map intoTuple
        |> Dict.fromList


scoresDecoder : Decode.Decoder (List ( String, Int ))
scoresDecoder =
    Decode.field "scores" (Decode.keyValuePairs Decode.int)


encodeState : App -> Encode.Value
encodeState model =
    Encode.object
        [ ( "players", Encode.list encodePlayer model.players )
        , ( "scores", Encode.dict encodeScoreKey Encode.int model.scores )
        ]


type Page
    = WelcomePage HomePage.Model
    | PlayerInputPage AddPlayersPage.Model
    | HolePage HolePage.Model
    | ResultsPage ResultsPage.Model
    | NotFoundPage


type alias App =
    { page : Page
    , players : List Player
    , scores : Scores
    , navKey : Nav.Key
    , route : Route
    , url : Url
    }


initCurrentPage model route =
    case route of
        Route.NotFound ->
            NotFoundPage

        Route.ResolveCourse courseId ->
            case findCourse courseId of
                Just course ->
                    WelcomePage { course = course }

                Nothing ->
                    NotFoundPage

        Route.Course courseSlug ->
            case findCourseBySlug courseSlug of
                Just course ->
                    WelcomePage { course = course }

                Nothing ->
                    NotFoundPage

        Route.AddPlayers courseSlug ->
            case findCourseBySlug courseSlug of
                Just course ->
                    PlayerInputPage
                        { course = course
                        , players = model.players
                        , newPlayerName = ""
                        }

                Nothing ->
                    NotFoundPage

        Route.Hole courseSlug holeId ->
            let
                maybeCourse =
                    findCourseBySlug courseSlug

                maybeHole =
                    maybeCourse |> Maybe.andThen (findHole holeId)
            in
            case ( maybeCourse, maybeHole ) of
                ( Just course, Just hole ) ->
                    HolePage
                        { course = course
                        , players = model.players
                        , scores = model.scores
                        , hole = hole
                        }

                _ ->
                    NotFoundPage

        Route.Results courseSlug ->
            case findCourseBySlug courseSlug of
                Just course ->
                    ResultsPage
                        { course = course
                        , players = model.players
                        , scores = model.scores
                        }

                Nothing ->
                    NotFoundPage


init : String -> Url -> Nav.Key -> ( App, Cmd Msg )
init flags url navKey =
    let
        players =
            case Decode.decodeString playersDecoder flags of
                Ok data ->
                    data

                Err _ ->
                    []

        scores =
            case Decode.decodeString scoresDecoder flags of
                Ok data ->
                    decodeScore data

                Err _ ->
                    Dict.empty

        route =
            Route.parseUrl url

        model =
            { page = initCurrentPage { players = players, scores = scores } route
            , players = players
            , scores = scores
            , navKey = navKey
            , route = route
            , url = url
            }

        cmd =
            case route of
                Route.ResolveCourse courseId ->
                    case findCourse courseId of
                        Just course ->
                            Nav.pushUrl model.navKey (Route.path (Route.Course course.slug))

                        Nothing ->
                            Cmd.none

                _ ->
                    Cmd.none
    in
    ( model, cmd )


view : App -> Browser.Document Msg
view model =
    let
        body =
            case model.page of
                WelcomePage pageModel ->
                    HomePage.view pageModel
                        |> Html.map HomeMsg

                PlayerInputPage pageModel ->
                    AddPlayersPage.view pageModel
                        |> Html.map AddPlayersMsg

                HolePage pageModel ->
                    HolePage.view pageModel
                        |> Html.map HolePageMsg

                ResultsPage pageModel ->
                    ResultsPage.view pageModel
                        |> Html.map ResultsMsg

                NotFoundPage ->
                    div [] [ text "" ]
    in
    { title = "Scores", body = [ body ] }


subscriptions : App -> Sub Msg
subscriptions model =
    Sub.none


main =
    Browser.application
        { init = init
        , update = update
        , subscriptions = subscriptions
        , view = view
        , onUrlRequest = LinkClicked
        , onUrlChange = UrlChanged
        }


type Msg
    = HomeMsg HomePage.Msg
    | AddPlayersMsg AddPlayersPage.Msg
    | HolePageMsg HolePage.Msg
    | ResultsMsg ResultsPage.Msg
    | LinkClicked Browser.UrlRequest
    | UrlChanged Url.Url


saveState : App -> Cmd Msg
saveState model =
    model
        |> encodeState
        |> Encode.encode 2
        |> Ports.storeState


saveStateDelux : App -> ( App, Cmd Msg )
saveStateDelux model =
    ( model, saveState model )


update : Msg -> App -> ( App, Cmd Msg )
update msg model =
    case ( msg, model.page ) of
        ( HomeMsg _, _ ) ->
            saveStateDelux model

        ( AddPlayersMsg pageMsg, PlayerInputPage pageModel ) ->
            let
                ( newPageModel, _ ) =
                    AddPlayersPage.update pageMsg pageModel

                players =
                    newPageModel.players
            in
            saveStateDelux { model | page = PlayerInputPage newPageModel, players = players }

        ( HolePageMsg pageMsg, HolePage pageModel ) ->
            let
                newPageModel =
                    HolePage.update pageMsg pageModel

                scores =
                    newPageModel.scores
            in
            saveStateDelux { model | page = HolePage newPageModel, scores = scores }

        ( ResultsMsg ResultsPage.ResetScore, ResultsPage pageModel ) ->
            let
                newModel =
                    { model | scores = Dict.empty, players = [] }

                browseToStartCmd =
                    Nav.pushUrl model.navKey (Route.path (Route.Course pageModel.course.slug))

                saveStateCmd =
                    saveState newModel
            in
            ( newModel, Cmd.batch [ browseToStartCmd, saveStateCmd ] )

        ( LinkClicked urlRequest, _ ) ->
            case urlRequest of
                Browser.Internal url ->
                    ( model, Nav.pushUrl model.navKey (Url.toString url) )

                Browser.External href ->
                    ( model, Nav.load href )

        ( UrlChanged url, _ ) ->
            let
                route =
                    Route.parseUrl url

                data =
                    { players = model.players, scores = model.scores }

                newPage =
                    initCurrentPage data route
            in
            ( { model | page = newPage }, Cmd.none )

        ( _, _ ) ->
            ( model, Cmd.none )
